import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AUTH_PREFIX_PATH, UNAUTHENTICATED_ENTRY, REDIRECT_URL_KEY } from "configs/AppConfig";
import { verifyToken } from "services/appServices/auth";
import Loading from "components/shared-components/Loading";
import { getProfileDetails } from "services/appServices/profile";
import { setUserDetails } from "store/slices/authSlice";

const ProtectedRoute = () => {
  const [authenticated, setAuthenticated] = useState({ loaded: false, verified: false });
  const token = localStorage.getItem("auth_token");
  const location = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    setUserProfileData();
    // verifyToken(setAuthenticated)
    // getProfileDetails().then((resp) => {
    // 	// console.log(resp);
    // 	dispatch(setUserDetails(resp?.data));
    // });
  }, []);

  const setUserProfileData = () => {
    verifyToken(setAuthenticated)
      .then((resp) => {
        dispatch(setUserDetails(resp?.data));
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  const sideNavTheme = useSelector((state) => state.auth.userData);

  if (!token) {
    return (
      <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
    );
  }

  if (!authenticated.loaded) {
    return <Loading />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
