import { apiRequest } from "utils/async/apiUtils";
import { message } from "antd";

export const loadSettingDetails = async (setResData = () => {}, setLoaded = () => {}) => {
  try {
    const res = await apiRequest("get", "site-settings/details");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return data;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const editSettings = async (data) => {
  try {
    const res = await apiRequest(
      "put",
      "site-settings/update",
      {
        body: {
          // id: data.id,
          title: data.title,
          description: data.description,
          email: data.email,
          phone: data.phone,
          "address[address1]": data.address,
          logo: data.logo,
          favicon: data.favicon,
        },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

// export const editSettingsSocial = async (params) => {
//   try {
//     const res = await apiRequest(
//       "put",
//       "site-settings/update",
//       {
//         body: {
//           ...params,
//         },
//       },
//       "multipart/form-data"
//     );
//     return res;
//   } catch (error) {
//     return error;
//   }
// };
export const editSettingsSocial = async (id, params) => {
  try {
    const res = await apiRequest(
      "put",
      "site-settings/update",
      {
        body: {
          id,
          // title: params.title,
          // region: params.region
          ...params,
        },
      },
      "multipart/form-data"
    );

    const data = res.data;

    return res;
  } catch (err) {
    throw new Error(err);
  }
};
